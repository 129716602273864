import React, { useEffect } from "react";
import Layout from "../layout";

import ServiceData from "./ServiceData";
import MoreServices from "../../CutisHome/MajorServices";

import serviceList from "../../data/allservices.json";

import { RedirectionArray } from "../../data/AllServicesRedirection.js";

import img1 from "../../assets/img/service/bg/service_banner.jpg";
import { contains } from "jquery";

const img1Div = { backgroundImage: "url(" + img1 + ")" };

const Index = () => {
  let { pathname } = window.location;
  let serviceData = serviceList.find((s) => {
    return s.link === pathname && s;
  });
  let service = {};
  if (serviceData && Object.keys(serviceData).length > 0) {
    service = serviceData;
  } else {
    window.location.replace("/");
  }

  // console.log(RedirectionArray.filter((item)=>item.redirectionLinks.contains(pathname)));
  let RedirectionArrayList = RedirectionArray.filter((item) =>
    item.redirectionLinks.includes(pathname)
  );

  if (RedirectionArrayList.length > 0) {
    //  const [link, redirectionLinks]= RedirectionArrayList[0]
    let { Link } = RedirectionArrayList[0];
    console.log(Link);
    window.location.href = Link;
  } else {
    console.log("not found");
  }

  // serviceList.map((item) => {
  //   console.log(`<url>
  //   <loc> https://cutis.org.in${item.link} </loc>
  //   </url>`);
  // });
  return (
    <>
      <Layout>
        <main id="main">
          <section id="serviceHero">
            <div className="serviceBg" style={img1Div}>
              <div className="inner">
                <h1>{service.title.toUpperCase()}</h1>
              </div>
            </div>

            <div className="serviceContent">
              <div className="row">
                <div className="col-md-12">
                  <ServiceData service={service} />
                </div>
              </div>
            </div>
          </section>
          <MoreServices />
        </main>
      </Layout>
    </>
  );
};

export default Index;
