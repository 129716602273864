import React, { useState } from "react";
import Layout from "../layout";

import BlogData from "./BlogData";
import MoreServices from "../home/featuredService/MajorServices";

import blogList from "../../data/blogs.json";

import img1 from "../../assets/img/service/bg/service_banner.jpg";
import BlogSchema from "./BlogSchema";
import PopUp from "./PopUp/PopUs";
import FaqList from "../home/FaqList";
const img1Div = { backgroundImage: "url(" + img1 + ")" };

const Index = () => {
  const [show, setShow] = useState(false);
  // console.log('href', pathname);
  const handleActiveClick = () => {
    setShow(!show);
  };
  let { pathname } = window.location;
  // console.log('href', pathname);

  let blogData = blogList.find((s) => {
    // console.log('S DATA', s.link);
    // if (s.link ==   pathname) {
    //     return s;
    // }
    return s.link === pathname && s;
  });

  let blog = {};
  // console.log('blogData', blogData);

  if (blogData && Object.keys(blogData).length > 0) {
    blog = blogData;
  } else {
    window.location.pathname = "/blog";
  }

  blog.title = blog.title.toUpperCase();

  // blogList.map((item) => {
  //   console.log(`<url>
  //   <loc> https://cutis.org.in${item.link} </loc>
  //   </url>`);
  // });

  return (
    <>
      <PopUp
        show={show}
        setShow={setShow}
        handleActiveClick={handleActiveClick}
      />
      <Layout>
        <main id="main">
          <section id="serviceHero" className="blogs">
            <div className="serviceBg" style={img1Div}>
              <div className="inner p-3">
                <h1>{blog.title}</h1>
              </div>
            </div>

            <div className="serviceContent">
              <div className="row">
                <div className="col-md-12">
                  <BlogData blog={blog} />
                </div>
              </div>
            </div>
          </section>
          <BlogSchema id={blogData.id} />

          {/* <MoreServices/> */}
          {blog?.data && (
            <div className="section-title section-title-faq mt-5 ">
              <h2>Faq</h2>
            </div>
          )}

          {blog?.data && (
            <section id="faq" className="faq">
              <div className="container" data-aos="fade-up">
                <div className="row">
                  <div className="col-lg-12 faqSecDiv faqServicesDiv">
                    {/* <div className="section-title"></div> */}
                    <FaqList arr={[blog]} />
                  </div>
                </div>
              </div>
            </section>
          )}
        </main>
      </Layout>
    </>
  );
};

export default Index;
