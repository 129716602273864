export const RedirectionArray = [
  {
    Link: "/service/anti-ageing-treatment-in-bangalore",
    redirectionLinks: ["/service/anti-ageing"],
  },
  {
    redirectionLinks: ["/service/aesthetics/cosmelan-peel"],
    Link: "/service/aesthetics/cosmelan-peel-treatment-in-bangalore",
  },
  {
    redirectionLinks: ["/service/hair/platelet-rich-plasma"],
    Link: "/service/hair/prp-hair-loss-treatment-in-bangalore",
  },
  {
    redirectionLinks: ["/service/skin/atopic-dermatitis"],
    Link: "/service/skin/atopic-dermatitis-treatment-in-bangalore",
  },
  {
    redirectionLinks: ["/service/skin/melasma"],
    Link: "/service/skin/melasma-treatment-in-bangalore",
  },

  // {
  //     redirectionLinks:["/service/skin/papular-urticaria"],
  //     Link:"/service/skin/papular-urticaria-treatment-in-bangalore",
  // },
  // {
  //     redirectionLinks:["/service/skin/pigmentation"],
  //     Link:"/service/skin/pigmentation-treatment-in-bangalore",
  // },
  // {
  //     redirectionLinks:["/service/skin/pimples"],
  //     Link:"/service/skin/pimples-treatment-in-bangalore",
  // },
  // {
  //     redirectionLinks:["/service/skin/psoriasis"],
  //     Link:"/service/skin/psoriasis-treatment-in-bangalore",
  // },
  // {
  //     redirectionLinks:["/service/skin/skin-allergy"],
  //     Link:"/service/skin/skin-allergy-treatment-in-bangalore",
  // },
  // {
  //     redirectionLinks:["/service/skin/vitiligo"],
  //     Link:"/service/skin/vitiligo-treatment-in-bangalore",
  // },
  // {
  //     redirectionLinks:["/service/hair/alopecia-areta"],
  //     Link:"/service/hair/alopecia-areta-treatment-in-bangalore",
  // },
  // {
  //     redirectionLinks:["/service/hair/hair-loss"],
  //     Link:"/service/hair/hair-loss-treatment-in-bangalore",
  // },
  // {
  //     redirectionLinks:["/service/hair/hairfall-control-and-hair-regrowth"],
  //     Link:"/service/hair/hairfall-control-and-hair-regrowth-treatment-in-bangalore",
  // },
  // {
  //     redirectionLinks:["/service/hair/hair-transplantation"],
  //     Link:"/service/hair/hair-transplantation-treatment-in-bangalore",
  // },
  // {
  //     redirectionLinks:["/service/hair/low-level-laser-therapy"],
  //     Link:"/service/hair/low-level-laser-therapy-treatment-in-bangalore",
  // },
  // {
  //     redirectionLinks:["/service/hair/male-hair-loss"],
  //     Link:"/service/hair/male-hair-loss-treatment-in-bangalore",
  // },
  // {
  //     redirectionLinks:["/service/skin-surgery/rigenera"],
  //     Link:"/service/skin-surgery/rigenera-treatment-in-bangalore",
  // },
  // {
  //     redirectionLinks:["/service/hair/scalp-micropigmentation"],
  //     Link:"/service/hair/scalp-micropigmentation-treatment-in-bangalore",
  // },
  // {
  //     redirectionLinks:["/service/nail/ingrow-toe-nail"],
  //     Link:"/service/nail/ingrow-toe-nail-treatment-in-bangalore",
  // },
  // {
  //     redirectionLinks:["/service/nail/nail-discoloration"],
  //     Link:"/service/nail/nail-discoloration-treatment-in-bangalore",
  // },
  // {
  //     redirectionLinks:["/service/nail/nail-dystrophy"],
  //     Link:"/service/nail/nail-dystrophy-treatment-in-bangalore",
  // },
  // {
  //     redirectionLinks:["/service/nail/nail-infection"],
  //     Link:"/service/nail/nail-infection-treatment-in-bangalore",
  // },
  // {
  //     redirectionLinks:["/service/nail/nail-tumors"],
  //     Link:"/service/nail/nail-tumors-treatment-in-bangalore",
  // },
  // {
  //     redirectionLinks:["/service/nail/nail-surgery"],
  //     Link:"/service/nail/nail-surgery-treatment-in-bangalore",
  // },
  // {
  //     redirectionLinks:["/service/nail/traumatic-nail"],
  //     Link:"/service/nail/traumatic-nail-treatment-in-bangalore",
  // },
  // {
  //     redirectionLinks:["/service/skin-surgery/acne-scar-surgery"],
  //     Link:"/service/skin-surgery/acne-scar-surgery-treatment-in-bangalore",
  // },
  // {
  //     redirectionLinks:["/service/skin-surgery/active-acne-procedure"],
  //     Link:"/service/skin-surgery/active-acne-procedure-treatment-in-bangalore",
  // },
  // {
  //     redirectionLinks:["/service/skin-surgery/blepharoplasty"],
  //     Link:"/service/skin-surgery/blepharoplasty-treatment-in-bangalore",
  // },
  // {
  //     redirectionLinks:["/service/skin-surgery/corn-removal"],
  //     Link:"/service/skin-surgery/corn-removal-treatment-in-bangalore",
  // },
  // {
  //     redirectionLinks:["/service/skin-surgery/corn-removal"],
  //     Link:"/service/skin-surgery/corn-removal-treatment-in-bangalore",
  // },
  // {
  //     redirectionLinks:["/service/skin-surgery/dimple-creation"],
  //     Link:"/service/skin-surgery/dimple-creation-treatment-in-bangalore",
  // },
  // {
  //     redirectionLinks:["/service/skin-surgery/autologous-fat-transfer"],
  //     Link:"/service/skin-surgery/autologous-fat-transfer-treatment-in-bangalore",
  // },
  // {
  //     redirectionLinks:["/service/hair/hair-transplantation"],
  //     Link:"/service/hair/hair-transplantation-treatment-in-bangalore",
  // },
  // {
  //     redirectionLinks:["/service/skin-surgery/lip-surgery"],
  //     Link:"/service/skin-surgery/lip-surgery-treatment-in-bangalore",
  // },
  // {
  //     redirectionLinks:["/service/skin-surgery/micro-pigmentation"],
  //     Link:"/service/skin-surgery/micro-pigmentation-treatment-in-bangalore",
  // },
  // {
  //     redirectionLinks:["/service/skin-suregry/moles-cysts-lipoma-surgery"],
  //     Link:"/service/skin-suregry/moles-cysts-lipoma-surgery-treatment-in-bangalore",
  // },
  // {
  //     redirectionLinks:["/service/nail/nail-surgery"],
  //     Link:"/service/nail/nail-surgery-treatment-in-bangalore",
  // },
  // {
  //     redirectionLinks:["/service/skin-surgery/sclerotherapy"],
  //     Link:"/service/skin-surgery/sclerotherapy-treatment-in-bangalore",
  // },
  // {
  //     redirectionLinks:["/service/thread-lift"],
  //     Link:"/service/thread-lift-treatment-in-bangalore",
  // },
  // {
  //     redirectionLinks:["/service/skin-surgery/vitiligo-surgery"],
  //     Link:"/service/skin-surgery/vitiligo-surgery-treatment-in-bangalore",
  // },
  // {
  //     redirectionLinks:["/service/aesthetics/acne-scar"],
  //     Link:"/service/aesthetics/acne-scar-treatment-in-bangalore",
  // },
  // {
  //     redirectionLinks:["/service/aesthetics/body-contouring"],
  //     Link:"/service/aesthetics/body-contouring-treatment-in-bangalore",
  // },
  // {
  //     redirectionLinks:["/service/chemical-peels"],
  //     Link:"/service/chemical-peels-treatment-in-bangalore",
  // },
  // {
  //     redirectionLinks:["/service/aesthetics/facial-rejuvination"],
  //     Link:"/service/aesthetics/facial-rejuvination-treatment-in-bangalore",
  // },
  // {
  //     redirectionLinks:["/service/gold-toning"],
  //     Link:"/service/gold-toning-treatment-in-bangalore",
  // },
  // {
  //     redirectionLinks:["/service/laser-hair-reduction"],
  //     Link:"/service/laser-hair-reduction-treatment-in-bangalore",
  // },
  // {
  //     redirectionLinks:["/service/laser-toning"],
  //     Link:"/service/laser-toning-treatment-in-bangalore",
  // },
  // {
  //     redirectionLinks:["/service/aesthetics/lip-tint-lip-toning"],
  //     Link:"/service/aesthetics/lip-tint-lip-toning-treatment-in-bangalore",
  // },
  // {
  //     redirectionLinks:["/service/aesthetics/oxy-hydrafacial"],
  //     Link:"/service/aesthetics/oxy-hydrafacial-treatment-in-bangalore",
  // },
  // {
  //     redirectionLinks:["/service/aesthetics/post-traumatic-scar"],
  //     Link:"/service/aesthetics/post-traumatic-scar-treatment-in-bangalore",
  // },
  // {
  //     redirectionLinks:["/service/aesthetics/ruvy-touch"],
  //     Link:"/service/aesthetics/ruvy-touch-treatment-in-bangalore",
  // },
  // {
  //     redirectionLinks:["/service/aesthetics/shining-peel"],
  //     Link:"/service/aesthetics/shining-peel-treatment-in-bangalore",
  // },
  // {
  //     redirectionLinks:["/service/aesthetics/skin-radiance"],
  //     Link:"/service/aesthetics/skin-radiance-treatment-in-bangalore",
  // },
  // {
  //     redirectionLinks:["/service/4d-facelift"],
  //     Link:"/service/4d-facelift-treatment-in-bangalore",
  // },
  // {
  //     redirectionLinks:["/service/mesojet"],
  //     Link:"/service/mesojet-treatment-in-bangalore",
  // },
  // {
  //     redirectionLinks:["/service/body-shaping"],
  //     Link:"/service/body-shaping-treatment-in-bangalore",
  // },
  // {
  //     redirectionLinks:["/service/anti-ageing/fat-fillers"],
  //     Link:"/service/anti-ageing/fat-fillers-surgery-treatment",
  // },
  // {
  //     redirectionLinks:["/service/anti-ageing/hifu"],
  //     Link:"/service/anti-ageing/best-hifu-treatment-in-bangalore",
  // },
  // {
  //     redirectionLinks:["/service/thread-lift"],
  //     Link:"/service/thread-lift-treatment-in-bangalore",
  // },
  // {
  //     redirectionLinks:["/service/anti-ageing/vampire-lift"],
  //     Link:"/service/anti-ageing/vampire-lift-treatment-in-bangalore",
  // },
  // {
  //     redirectionLinks:["/service/lasers-and-lights"],
  //     Link:"/service/lasers-and-lights-treatment-in-bangalore",
  // },
  // {
  //     redirectionLinks:["/service/pediatric-dermatology"],
  //     Link:"/service/pediatric-dermatology-treatment-in-bangalore",
  // },
  // {
  //     redirectionLinks:["/service/geriatric-dermatology"],
  //     Link:"/service/geriatric-dermatology-treatment-in-bangalore",
  // },
  // {
  //     redirectionLinks:["/service/biological-therapy"],
  //     Link:"/service/biological-therapy-treatment-in-bangalore",
  // },
];
